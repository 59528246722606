var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      [
        _c(
          "v-card",
          { staticStyle: { "max-width": "800px" } },
          [
            _c("v-card-title", [_vm._v(" Strom ")]),
            _c("v-card-text", [
              _c(
                "table",
                {
                  staticStyle: {
                    "box-shadow": "0px 4px 6px rgba(0, 0, 0, 0.2)",
                  },
                },
                [
                  _c("thead", [
                    _c("tr", [
                      _c("th", [_vm._v("Jahr")]),
                      _c("th", [_vm._v("zeitbereiche")]),
                      _c("th", [_vm._v("limit")]),
                      _c("th", [_vm._v("stopploss")]),
                    ]),
                  ]),
                  _c(
                    "tbody",
                    [
                      _vm._l(Object.keys(_vm.stromLimits), function (art) {
                        return [
                          _c("tr", { key: art }, [
                            _c(
                              "td",
                              {
                                staticStyle: {
                                  "border-bottom": "2px solid black",
                                },
                              },
                              [_vm._v(_vm._s(art))]
                            ),
                            _c(
                              "td",
                              { staticStyle: { padding: "0px" } },
                              [
                                _vm._l(
                                  Object.keys(_vm.stromLimits[art]),
                                  function (subart, index) {
                                    return [
                                      _c(
                                        "div",
                                        {
                                          key: subart,
                                          style: {
                                            borderBottom:
                                              index ===
                                              Object.keys(_vm.stromLimits[art])
                                                .length -
                                                1
                                                ? "none"
                                                : "1px solid black",
                                            padding: "8px",
                                          },
                                        },
                                        [_vm._v(" " + _vm._s(subart) + " ")]
                                      ),
                                    ]
                                  }
                                ),
                              ],
                              2
                            ),
                            _c(
                              "td",
                              { staticStyle: { padding: "0px" } },
                              [
                                _vm._l(
                                  Object.keys(_vm.stromLimits[art]),
                                  function (subart, index) {
                                    return [
                                      _c(
                                        "div",
                                        {
                                          key: _vm.stromLimits[art][subart]
                                            .limit,
                                          style: {
                                            borderBottom:
                                              index ===
                                              Object.keys(_vm.stromLimits[art])
                                                .length -
                                                1
                                                ? "none"
                                                : "1px solid black",
                                            padding: "8px",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.stromLimits[art][subart]
                                                  .limit
                                              ) +
                                              " €/MWh "
                                          ),
                                        ]
                                      ),
                                    ]
                                  }
                                ),
                              ],
                              2
                            ),
                            _c(
                              "td",
                              { staticStyle: { padding: "0px" } },
                              [
                                _vm._l(
                                  Object.keys(_vm.stromLimits[art]),
                                  function (subart, index) {
                                    return [
                                      _c(
                                        "div",
                                        {
                                          key: _vm.stromLimits[art][subart]
                                            .stopploss,
                                          style: {
                                            borderBottom:
                                              index ===
                                              Object.keys(_vm.stromLimits[art])
                                                .length -
                                                1
                                                ? "none"
                                                : "1px solid black",
                                            padding: "8px",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.stromLimits[art][subart]
                                                  .stopploss
                                              ) +
                                              " €/MWh "
                                          ),
                                        ]
                                      ),
                                    ]
                                  }
                                ),
                              ],
                              2
                            ),
                          ]),
                        ]
                      }),
                    ],
                    2
                  ),
                ]
              ),
            ]),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticStyle: { "margin-top": "30px" } },
      [
        _c(
          "v-card",
          { staticStyle: { "max-width": "800px" } },
          [
            _c("v-card-title", [_vm._v(" Gas ")]),
            _c("v-card-text", [
              _c(
                "table",
                {
                  staticStyle: {
                    "box-shadow": "0px 4px 6px rgba(0, 0, 0, 0.2)",
                  },
                },
                [
                  _c("thead", [
                    _c("tr", [
                      _c("th", [_vm._v("Jahr")]),
                      _c("th", [_vm._v("zeitbereiche")]),
                      _c("th", [_vm._v("limit")]),
                      _c("th", [_vm._v("stopploss")]),
                    ]),
                  ]),
                  _c(
                    "tbody",
                    [
                      _vm._l(Object.keys(_vm.gasLimits), function (art) {
                        return [
                          _c("tr", { key: art }, [
                            _c(
                              "td",
                              {
                                staticStyle: {
                                  "border-bottom": "2px solid black",
                                },
                              },
                              [_vm._v(_vm._s(art))]
                            ),
                            _c(
                              "td",
                              { staticStyle: { padding: "0px" } },
                              [
                                _vm._l(
                                  Object.keys(_vm.gasLimits[art]),
                                  function (subart, index) {
                                    return [
                                      _c(
                                        "div",
                                        {
                                          key: subart,
                                          style: {
                                            borderBottom:
                                              index ===
                                              Object.keys(_vm.gasLimits[art])
                                                .length -
                                                1
                                                ? "none"
                                                : "1px solid black",
                                            padding: "8px",
                                          },
                                        },
                                        [_vm._v(" " + _vm._s(subart) + " ")]
                                      ),
                                    ]
                                  }
                                ),
                              ],
                              2
                            ),
                            _c(
                              "td",
                              { staticStyle: { padding: "0px" } },
                              [
                                _vm._l(
                                  Object.keys(_vm.gasLimits[art]),
                                  function (subart, index) {
                                    return [
                                      _c(
                                        "div",
                                        {
                                          key: _vm.gasLimits[art][subart].limit,
                                          style: {
                                            borderBottom:
                                              index ===
                                              Object.keys(_vm.gasLimits[art])
                                                .length -
                                                1
                                                ? "none"
                                                : "1px solid black",
                                            padding: "8px",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.gasLimits[art][subart].limit
                                              ) +
                                              " €/MWh "
                                          ),
                                        ]
                                      ),
                                    ]
                                  }
                                ),
                              ],
                              2
                            ),
                            _c(
                              "td",
                              { staticStyle: { padding: "0px" } },
                              [
                                _vm._l(
                                  Object.keys(_vm.gasLimits[art]),
                                  function (subart, index) {
                                    return [
                                      _c(
                                        "div",
                                        {
                                          key: _vm.gasLimits[art][subart]
                                            .stopploss,
                                          style: {
                                            borderBottom:
                                              index ===
                                              Object.keys(_vm.gasLimits[art])
                                                .length -
                                                1
                                                ? "none"
                                                : "1px solid black",
                                            padding: "8px",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.gasLimits[art][subart]
                                                  .stopploss
                                              ) +
                                              " €/MWh "
                                          ),
                                        ]
                                      ),
                                    ]
                                  }
                                ),
                              ],
                              2
                            ),
                          ]),
                        ]
                      }),
                    ],
                    2
                  ),
                ]
              ),
            ]),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }